import _defineProperty from "/builds/outsource/mem/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "vant/es/cell-group/style";
import _CellGroup from "vant/es/cell-group";
import "vant/es/button/style";
import _Button from "vant/es/button";
import "vant/es/popover/style";
import _Popover from "vant/es/popover";
import "vant/es/field/style";
import _Field from "vant/es/field";

var _components;

import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent, ref, computed } from 'vue';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'FieldSelector',
  components: (_components = {}, _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _Popover.name, _Popover), _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _CellGroup.name, _CellGroup), _defineProperty(_components, _Cell.name, _Cell), _defineProperty(_components, "SvgIcon", SvgIcon), _components),
  props: {
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    showValue: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    slot: {},
    searchPlaceholder: {
      type: String
    },
    selectedValue: {},
    onSelect: {
      type: Function,
      default: function _default() {} // eslint-disable-line @typescript-eslint/no-empty-function

    },
    labelOptions: {
      type: Function
    }
  },
  setup: function setup(props) {
    var showPopover = ref(false);
    var keyword = ref('');
    var filteredOptions = computed(function () {
      return props.options.filter(function (option) {
        if (keyword.value !== '') {
          var _keyword = keyword.value.toLowerCase();

          var label = option.label,
              value = option.value;
          return ("".concat(label).toLowerCase() || '').includes(_keyword) || ("".concat(value).toLowerCase() || '').includes(_keyword);
        }

        return true;
      });
    });

    var handleSelect = function handleSelect(val) {
      props && props.onSelect(val);
      showPopover.value = false;
    };

    return {
      keyword: keyword,
      showPopover: showPopover,
      filteredOptions: filteredOptions,
      handleSelect: handleSelect
    };
  }
});