import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-26ee8da6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "search-div"
};
var _hoisted_2 = ["placeholder"];
var _hoisted_3 = ["onClick"];
var _hoisted_4 = {
  class: "icon"
};
var _hoisted_5 = {
  key: 0,
  class: "item-value"
};
var _hoisted_6 = {
  class: "item-label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_svg_icon = _resolveComponent("svg-icon");

  var _component_van_cell = _resolveComponent("van-cell");

  var _component_van_cell_group = _resolveComponent("van-cell-group");

  var _component_van_popover = _resolveComponent("van-popover");

  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      selector__wrapper: true,
      focus: _ctx.showPopover
    })
  }, [_createVNode(_component_van_popover, {
    show: _ctx.showPopover,
    "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
      return _ctx.showPopover = $event;
    }),
    onSelect: _ctx.onSelect,
    "show-arrow": false,
    placement: "bottom-start",
    overlay: "",
    "overlay-class": "popover-overlay"
  }, {
    reference: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default", {}, undefined, true)];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_van_cell_group, {
        class: "cell-group"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_cell, {
            class: "van-cell-custom"
          }, {
            title: _withCtx(function () {
              return [_createElementVNode("div", _hoisted_1, [_createVNode(_component_svg_icon, {
                class: "search-icon",
                iconName: "search"
              }), _withDirectives(_createElementVNode("input", {
                class: "search-input",
                placeholder: _ctx.searchPlaceholder,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
                  return _ctx.keyword = $event;
                })
              }, null, 8, _hoisted_2), [[_vModelText, _ctx.keyword]])])];
            }),
            _: 1
          }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredOptions, function (option) {
            return _openBlock(), _createBlock(_component_van_cell, {
              class: "van-cell-custom",
              key: "option-".concat(option.value)
            }, {
              title: _withCtx(function () {
                return [_createElementVNode("div", {
                  class: _normalizeClass({
                    'cell-item': true,
                    'cell-item--active': _ctx.selectedValue === option.value
                  }),
                  onClick: function onClick($event) {
                    return _ctx.handleSelect(option.value);
                  }
                }, [_createElementVNode("span", _hoisted_4, [_ctx.selectedValue === option.value ? (_openBlock(), _createBlock(_component_svg_icon, {
                  key: 0,
                  iconName: "icon_24_dropdownmenu_check",
                  class: "check-icon"
                })) : _createCommentVNode("", true)]), _ctx.showValue ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(option.value), 1)) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.labelOptions ? _ctx.labelOptions(option.label) : option.label), 1)], 10, _hoisted_3)];
              }),
              _: 2
            }, 1024);
          }), 128))];
        }),
        _: 1
      })];
    }),
    _: 3
  }, 8, ["show", "onSelect"])], 2);
}